/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import slugify from 'slugify'
import { RiArrowRightSLine } from "react-icons/ri"

const postPrefix = `/`
const PostCard = ({ data }) => (
  <div sx={blogStyles.postContent}>
    <h2 
      sx={blogStyles.title}
    >
      <Link to={ postPrefix + slugify(`${data.frontmatter.title}`)}>
        <RiArrowRightSLine className="icon -left"/> {data.frontmatter.title}
      </Link>
    </h2>

  </div>
)

export default PostCard

const blogStyles = {
  postContent: {
    p:"20px 0"
  },
  title: {
    pt:"20px",
    mt:0,
    fontSize: ["32px", "32px", "36px"],
    fontWeight:" 600",
    mb: "0",
    "a": { 
      color: "textColor",
      "&:hover": {
        color:"#517701"
      }
    }
  }
}
