/** @jsx jsx */
import { jsx } from "theme-ui"
import Layout from "../components/layout"
import BlogListHome from "../components/blog-list-home"
import SEO from "../components/seo"

const HomePage = () => {
	return (
		<Layout>
			<SEO/>
			<BlogListHome/>
		</Layout>
	)
}

export default HomePage