/** @jsx jsx */
import { jsx } from "theme-ui"
import { StaticQuery, graphql } from "gatsby"

import PostCard from "./post-card"

export default function BlogListHome() {
  return (
    <StaticQuery 
      query={graphql`
        query {
          allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { template: { eq: "blog-post" } } }
          ) {
            edges {
              node {
                id
                excerpt(pruneLength: 250)
                frontmatter {
                  date(formatString: "MMMM DD, YYYY")
                  title
                }
              }
            }
          }
        }`
      }
      render = { data => {
          const posts = data.allMarkdownRemark.edges
            .filter(edge => !!edge.node.frontmatter.date)
            .map(edge =>
              <PostCard key={edge.node.id} data={edge.node} />
            )
          return <PostMaker data={posts} />
        } 
      }
    />
  )
}

const PostMaker = ({ data }) => (
  <div sx={blogStyles.blogListHome}>
    <div sx={{variant: "variants.container"}}>
      <h1 sx={blogStyles.h1}>Knowledge base</h1>
      <div sx={blogStyles.blogListContainer}>
        {data}
      </div>
    </div>
    </div>
)

const blogStyles = {
  h1: {
    textTransform: "uppercase",
    color: "secondary"
  },
  blogListHome: { 
    maxWidth: "100%",
    height: "100vh",
    p: "48px 0",
  },
  blogListContainer:{
    display: "grid",
    gridTemplateColumns: ["1fr", "1fr 1fr", "1fr 1fr 1fr"],
    gap: "30px"
  }
}